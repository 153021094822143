// If you don't want to use TypeScript you can delete this file!
import React from 'react'

import { SEO } from 'src/components/seo'
import { RenderModules } from 'src/utils/renderModules'
import { Image } from 'src/components/image'
import Content from 'src/components/content'

export interface JournalProps {
  pageContext: {
    main: {
      modules: []
      mantra?: string
      title: string
      slug: {
        current: string
      }
    }
    meta: {}
    env: 'production' | 'development' | 'local' | 'staging'
  }
  path: string
}

const Journal = ({ path, pageContext }: JournalProps) => {
  const {
    main: { title, mantra, logo, modules, shapes, slug },
    meta,
    env,
  } = pageContext

  return (
    <div className="bcb journal__index cw">
      <SEO metaInfo={meta} pagePath={path} env={env} />
      <div className="journal__header z1 rel p1 f jcc aic pym ac">
        <div className="rel z2">
          {logo ? (
            <Image imageId={logo.asset._id} alt={title} className="x" />
          ) : (
            <h1 className="mono h2 rel mxa caps">{title}</h1>
          )}
          <h3 className="mxa">{mantra}</h3>
        </div>
      </div>
      <div className="container--l mxa outer px1">
        <div
          className="cta__squiggle x"
          style={{ backgroundImage: 'url(/squiggle.png)' }}
        />
      </div>
      <Content modules={modules}></Content>
    </div>
  )
}

export default Journal
